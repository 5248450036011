import BaseModel from "@/models/BaseModel.js";

export default class Addon extends BaseModel {
  fields = {
    name: {
      type: "text",
      label: "nama",
      rules: "required",
    },
    description: {
      type: "text",
      label: "Deskripsi",
      rules: "required",
    },
    price: {
      type: "number",
      label: "Harga",
      rules: "required",
    },
    type: {
      label: "Tipe",
      type: "enum",
      options: {
        departure: "Departure",
        room: "Room",
      },
    },
    is_active: {
      label: "Status",
      type: "enum",
      options: {
        0: "Tidak Aktif",
        1: "Aktif",
      },
      rules: "required",
    },
  };
  endpoint = process.env.VUE_APP_API_URL + "addons";
}
